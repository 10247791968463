/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Trans } from "react-i18next";

const ColorButton = withStyles(() => ({
  root: {
    color: "White",
    backgroundColor: "#1a344a",
    "&:hover": {
      color: "#1a344a",
      backgroundColor: "White",
    },
  },
}))(Button);
const useStyles = makeStyles((theme) => ({
  paper: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    marginTop: "0px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textEmail: {
    textAlign: "center",
  },
}));

export default function SignUpComponent() {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <SendIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        <Trans i18nKey="E-mail de vérification">E-mail de vérification</Trans>
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.textEmail}>
          <p>
            <Trans i18nKey="Un email de vérification vient de vous être envoyé.">
              Un email de vérification vient de vous être envoyé.
            </Trans>
            <br />
            <Trans i18nKey="Veuillez vérifier votre boite mail s'il vous plait afin de valider votre inscription.">
              Veuillez vérifier votre boite mail s'il vous plait afin de valider
              votre inscription.
            </Trans>
          </p>
        </Grid>
      </Grid>
      <ColorButton
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        href="/auth/sign-in"
      >
        <Trans i18nKey="Connexion">Connexion</Trans>
      </ColorButton>
    </div>
  );
}
