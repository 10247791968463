import React, { useState } from "react";
import styled from "styled-components";
import {
  Trans,
  // Normally used to add range from product at creation but not working ATM, temporary commented
  // useTranslation,
} from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  InputLabel,
  // Normally used to add range from product at creation but not working ATM, temporary commented
  // Chip,
  // MenuItem,
  // FormControl,
  // Select,
  // Input,
} from "@material-ui/core";
// import { DropzoneArea } from "material-ui-dropzone";
// Normally used to add range from product at creation but not working ATM, temporary commented
// import { Controller } from "react-hook-form";

import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";

const Card = styled(MuiCard)(spacing);
// Normally used to add range from product at creation but not working ATM, temporary commented
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// Normally used to add range from product at creation but not working ATM, temporary commented
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
const TextField = styled(MuiTextField)(spacing);
const useStyles = makeStyles(() => ({
  inputImg: {
    visibility: "hidden",
  },
  labelImg: {
    textAlign: "center",
    border: "solid",
  },
}));
function AddProductDetail({ register, setImg, errors, control, listRange }) {
  // Temporary to avoid unused variable error, but variable used to add range
  // eslint-disable-next-line no-unused-vars
  const tmpUnused = { control, listRange };
  // Normally used to add range from product at creation but not working ATM, temporary commented
  // const [range, setrange] = useState([]);
  const classes = useStyles();
  const [image, setImage] = useState({
    file: `${process.env.PUBLIC_URL}/default_product.png`,
  });
  // Normally used to add range from product at creation but not working ATM, temporary commented
  // const { t } = useTranslation();

  // Normally used to add range from product at creation but not working ATM, temporary commented
  // const handleChange = (event) => {
  //   setrange(event.target.value);
  // };
  const handleChangeImg = (event) => {
    setImage({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setImg(event.target.files[0]);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label={
                    <Trans i18nKey="Nom du produit">Nom du produit *</Trans>
                  }
                  name="name"
                  autoFocus
                  inputRef={register({
                    required: "Required",
                  })}
                  error={"name" in errors}
                  helperText={
                    "name" in errors ? (
                      <Trans i18nKey="Veuillez entrer le nom du produit">
                        Veuillez entrer le nom du produit
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="seasonnality"
                  label={<Trans i18nKey="Saisonnalité">Saisonnalité</Trans>}
                  name="seasonnality"
                  inputRef={register()}
                />
              </Grid>
              {/* Normally used to add range from product at creation but not working ATM, temporary commented
              <Grid item xs={6}>
                {listRange.length !== 0 ? (
                  <FormControl xs={2} fullWidth>
                    <InputLabel htmlFor="select-multiple-chi">
                      <Trans i18nKey="Gammes">Gammes</Trans>
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          multiple
                          disabled={listRange.length === 0}
                          displayEmpty="true"
                          value={range}
                          onChange={handleChange}
                          defaultValue={[]}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.name} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {listRange.map((name) => (
                            <MenuItem key={name.id} value={name}>
                              {name.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      id="range"
                      name="range"
                      control={control}
                    />
                  </FormControl>
                ) : (
                  <TextField
                    fullWidth
                    disabled
                    id="range-null"
                    type="text"
                    placeholder={t("Pas de gamme créé")}
                    label={<Trans i18nKey="Gammes">Gammes</Trans>}
                    name="range-null"
                  />
                )}
              </Grid> */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="number"
                  type="number"
                  inputRef={register()}
                  label={
                    <Trans i18nKey="Nombre d'unités produites">
                      Nombre d&apos;unités produites
                    </Trans>
                  }
                  name="number"
                  defaultValue="1"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Grid item xs={12}>
              <div>
                <input
                  type="file"
                  id="img"
                  name="product-image"
                  onChange={handleChangeImg}
                  className={classes.inputImg}
                />
                <p>Image :</p>
                <InputLabel htmlFor="img" className={classes.labelImg}>
                  <img
                    width="300px"
                    height="300px"
                    src={image.file}
                    alt="empty"
                  />
                </InputLabel>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
AddProductDetail.propTypes = {
  register: PropTypes.func.isRequired,
  setImg: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  control: PropTypes.func.isRequired,
  listRange: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      owner: PropTypes.number,
      seasonnality: PropTypes.string,
      tva_rate: PropTypes.number,
    })
  ),
};

AddProductDetail.defaultProps = {
  listRange: [],
};
export default AddProductDetail;
