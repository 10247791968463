import { TableCell, TableRow, TextField, IconButton } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Delete as DeleteIcon } from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  deleteWorkforceCost,
  putWorkforceCost,
} from "../../redux/actions/sigActions";
import useDebounce from "../../services/useDebounce";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import {
  getParsedFloatNumber,
  toFixedNumber,
} from "../../services/range/RangeService";
import { UPDATE_WORKFORCE_COSTS_SUMMARY } from "../../redux/types/sigActionTypes";
import {
  getExternalCostTotal,
  getExternalCostTotalFixedPart,
  getExternalCostTotalFixedPartPercentage,
  getExternalCostTotalPercentage,
} from "../../services/external_costs/external_costs_summary";
import {
  getIndicatorFixedPartAmount,
  getIndicatorFixedPartPercentage,
  getIndicatorPercentage,
} from "../../services/external_costs/external_costs_service";
import { getTotalProduct } from "../../services/sig/SigCalculService";

const WorkforceCost = ({ external_cost_uuid }) => {
  const { register, control, errors } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [workforce_cost, setWorkforceCost] = useState({});
  const workforce_costs = useSelector((state) => state.sig.workforce_costs);
  const sig = useSelector((state) => state.sig.sig);
  const workforce_costs_summary = useSelector(
    (state) => state.sig.workforce_costs_summary
  );

  useEffect(() => {
    const filtered_external_costs = [
      ...workforce_costs.filter((ec) => ec.uuid === external_cost_uuid),
    ];
    if (filtered_external_costs.length > 0) {
      setWorkforceCost(filtered_external_costs[0]);
    }
  }, [workforce_costs, sig]);
  const debouncedExternalCost = useDebounce(workforce_cost, 300);

  useEffect(async () => {
    if (
      debouncedExternalCost.uuid === external_cost_uuid &&
      debouncedExternalCost.user_input
    ) {
      await dispatch(
        putWorkforceCost(
          debouncedExternalCost,
          workforce_costs,
          user.accessToken
        )
      );
      dispatch(
        openSnackbar(
          t(
            `La charge de personnel ${debouncedExternalCost.line_name} à bien été mise à jour`
          ),
          "success"
        )
      );
      const external_cost_total = getExternalCostTotal(workforce_costs);
      dispatch({
        type: UPDATE_WORKFORCE_COSTS_SUMMARY,
        payload: {
          ...workforce_costs_summary,
          workforce_cost_total: external_cost_total,
          workforce_cost_total_percentage: getExternalCostTotalPercentage(
            external_cost_total,
            sig
          ),
          workforce_cost_total_fixed_part: getExternalCostTotalFixedPart(
            workforce_costs
          ),
          workforce_cost_total_fixed_part_percentage: getExternalCostTotalFixedPartPercentage(
            workforce_costs
          ),
        },
      });
    }
  }, [debouncedExternalCost]);
  const removeExternalCost = async () => {
    await dispatch(deleteWorkforceCost(workforce_cost.uuid, user.accessToken));
    const external_cost_total = getExternalCostTotal(workforce_costs);
    await dispatch({
      type: UPDATE_WORKFORCE_COSTS_SUMMARY,
      payload: {
        ...workforce_costs_summary,
        workforce_cost_total: external_cost_total,
        workforce_cost_total_percentage: getExternalCostTotalPercentage(
          external_cost_total,
          sig
        ),
        workforce_cost_total_fixed_part: getExternalCostTotalFixedPart(
          workforce_costs
        ),
        workforce_cost_total_fixed_part_percentage: getExternalCostTotalFixedPartPercentage(
          workforce_costs
        ),
      },
    });
  };

  const handleChangeExternalCostAmount = async (formValue) => {
    const indicator = toFixedNumber(getParsedFloatNumber(formValue), 2);
    setWorkforceCost({
      ...workforce_cost,
      indicator,
      indicator_percentage: getIndicatorPercentage(
        indicator,
        getTotalProduct(sig)
      ),
      indicator_amount_fixed_part: getIndicatorFixedPartAmount(
        workforce_cost.indicator_percentage_fixed_part,
        indicator
      ),
      user_input: true,
    });
  };

  const handleChangeExternalCostFixedPartAmount = (formValue) => {
    const indicator = toFixedNumber(
      getParsedFloatNumber(workforce_cost.indicator),
      2
    );
    const indicator_amount_fixed_part = toFixedNumber(
      getParsedFloatNumber(formValue),
      2
    );
    setWorkforceCost({
      ...workforce_cost,
      indicator_amount_fixed_part,
      indicator_percentage_fixed_part: getIndicatorFixedPartPercentage(
        indicator_amount_fixed_part,
        indicator
      ),
      user_input: true,
    });
  };

  const handleChangeExternalCostFixedPartPercentage = (formValue) => {
    const indicator_percentage_fixed_part = toFixedNumber(
      getParsedFloatNumber(formValue),
      2
    );
    setWorkforceCost({
      ...workforce_cost,
      indicator_amount_fixed_part: getIndicatorFixedPartAmount(
        indicator_percentage_fixed_part,
        workforce_cost.indicator
      ),
      indicator_percentage_fixed_part,
      user_input: true,
    });
  };

  return (
    <TableRow key="raw_material_purchases">
      <TableCell component="th" scope="row">
        <TextField
          id={workforce_cost.uuid}
          onChange={(e) =>
            // eslint-disable-next-line prettier/prettier
            setWorkforceCost({
              ...workforce_cost,
              line_name: e.target.value,
              user_input: true,
            })
          }
          value={workforce_cost.line_name}
        />
      </TableCell>
      <TableCell align="right">
        {" "}
        <FieldWithSuffix
          isEdit
          disabled={false}
          name="external_cost_amount"
          control={control}
          errors={errors}
          suffix={t("€")}
          value={workforce_cost.indicator}
          onChangeField={(e) => handleChangeExternalCostAmount(e.target.value)}
          defaultValue={0}
          register={register}
        />
      </TableCell>
      <TableCell align="right">
        {" "}
        <FieldWithSuffix
          disabled
          name="raw_material_purchases_percentage"
          control={control}
          errors={errors}
          suffix={t("%")}
          value={workforce_cost.indicator_percentage}
          defaultValue={0}
          register={register}
        />
      </TableCell>
      <TableCell align="right">
        {" "}
        <FieldWithSuffix
          isEdit
          disabled={false}
          name="change_in_inventories_raw_material_devise_part_fixe"
          control={control}
          errors={errors}
          suffix={t("€")}
          value={workforce_cost.indicator_amount_fixed_part}
          onChangeField={(e) =>
            handleChangeExternalCostFixedPartAmount(
              e.target.value.slice(0, e.target.value.length - 1)
              // eslint-disable-next-line prettier/prettier
            )
          }
          defaultValue={0}
          register={register}
        />
      </TableCell>
      <TableCell align="right">
        {" "}
        <FieldWithSuffix
          isEdit
          disabled={false}
          name="change_in_inventories_raw_material_percentage_part_fixe"
          control={control}
          errors={errors}
          suffix={t("%")}
          value={workforce_cost.indicator_percentage_fixed_part}
          onChangeField={(e) =>
            // eslint-disable-next-line prettier/prettier
            handleChangeExternalCostFixedPartPercentage(
              e.target.value.slice(0, e.target.value.length - 1)
            )
          }
          defaultValue={0}
          register={register}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton aria-label="delete" onClick={removeExternalCost}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
WorkforceCost.propTypes = {
  external_cost_uuid: PropTypes.number.isRequired,
};

export default WorkforceCost;
