import { getParsedFloatNumber, toFixedNumber } from "../range/RangeService";

export const getListTotalEnergy = (listRawMaterial) => {
  return Array.from(listRawMaterial, (rawMaterial) =>
    toFixedNumber(getParsedFloatNumber(rawMaterial.energy), 10)
  );
};
export const getListTotalPrice = (listRawMaterial) => {
  const listTotalPrice = [];
  listRawMaterial.forEach((element) => {
    listTotalPrice.push(parseFloat(element.total_price));
  });

  return listTotalPrice;
};
