export const is2Decimal = (float) => {
  if (float.split(".")[1]) {
    if (float.split(".")[1].length > 2) {
      return false;
    }
  }
  if (float.split(",")[1]) {
    if (float.split(",")[1].length > 2) {
      return false;
    }
  }
  return true;
};

export default is2Decimal;

export const is10Decimal = (float) => {
  if (float.split(".")[1]) {
    if (float.split(".")[1].length > 10) {
      return false;
    }
  }
  if (float.split(",")[1]) {
    if (float.split(",")[1].length > 10) {
      return false;
    }
  }
  return true;
};
