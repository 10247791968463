import React from "react";
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n";
import { firebase } from "./services/firebase";
import { persistor, store } from "./redux/store";
// Wait for auth to be loaded. While loading display a loading page

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
};
let environment_name = process.env.REACT_APP_POPSER_ENVIRONMENT_NAME;

if (typeof environment_name === "undefined") {
  environment_name = "local";
}

ReactDOM.render(
  <React.StrictMode>
    <Helmet titleTemplate="%s | Popser" defaultTitle="Popser" />
    <I18nextProvider i18n={i18n}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ReactReduxFirebaseProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
