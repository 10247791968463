import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  InputLabel,
  Chip,
  Box,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
// Normally used to add range from product, temporary commented
// import Select from "react-select";
import { updateProduct } from "../../redux/actions/productActions";
import { fetchProjectRanges } from "../../redux/actions/rangeActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import { interactionNumberUnitProduced } from "../../services/product/TechnicalSheetCalculInteractionService";
import { updateTechnicalSheet } from "../../redux/actions/technicalSheetActions";

import {
  // Normally used to add range from product, temporary commented
  // addProductToRange,
  // removeProductFromRange,
  fetchRangesOfAProduct,
} from "../../redux/actions/rangeProductActions";
import useDebounce from "../../services/useDebounce";

const Card = styled(MuiCard)(spacing);

// Normally used to add range from product, temporary commented
// const generateRangeOptions = (projectRanges) => {
//   return projectRanges.map((projectRange) => ({
//     value: projectRange.id,
//     label: projectRange.name,
//   }));
// };

const TextField = styled(MuiTextField)(spacing);
const useStyles = makeStyles(() => ({
  inputImg: {
    visibility: "hidden",
  },
  labelImg: {
    textAlign: "center",
    border: "solid",
  },
  rangesBox: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "3px",
    columnGap: "5px",
    maxHeight: "240px",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "10px",
    border: "1px solid rgb(118, 118, 118)",
    borderRadius: "4px",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
}));
const defaultImage = `${process.env.PUBLIC_URL}/default_product.png`;

const ProductDetail = ({ productId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  /* STORE DATA */
  const productState = useSelector((state) => state.product);
  const product = useSelector((state) => state.product.product);

  // Normally used to add range from product, temporary commented
  // const projectRanges = useSelector((state) => state.ranges.ranges);
  const rangesOfTheProduct = useSelector(
    (state) => state.rangeProducts.rangeProducts
  ).map((rangeProduct) => rangeProduct.range);
  // Normally used to add range from product, temporary commented
  // const rangeProducts = useSelector(
  //   (state) => state.rangeProducts.rangeProducts
  // );
  const auth = useSelector((state) => state.firebase.auth);
  const currentProject = useSelector((state) => state.projects.currentProject);
  /* ******** */

  const user = auth.stsTokenManager;
  const [productForm, setProductForm] = useState({});
  const productLocalState = useDebounce(productForm, 1000);
  const onChangeNumberUnitProduced = (event) => {
    if (event.target.value !== "0") {
      const resultTechnicalSheet = interactionNumberUnitProduced(
        event.target.value
      );
      dispatch(
        updateTechnicalSheet(
          resultTechnicalSheet,
          user.accessToken,
          productId,
          currentProject.id
        )
      );
      dispatch(openSnackbar("Fiche technique modifiée avec succès", "Success"));
    }
  };
  // When the productId changes, we dispatch actions to update the state of the product, the project ranges and the ranges assigned to the product
  useEffect(async () => {
    await dispatch(fetchProjectRanges(currentProject.id, user.accessToken));
    await dispatch(fetchRangesOfAProduct(productId, user.accessToken));
    if (
      Object.keys(productForm).length === 0 &&
      Object.keys(product).length !== 0
    ) {
      setProductForm({
        name: product.name,
        id: product.id,
        seasonnality: product.seasonnality,
        number_produced: product.number_produced,
      });
    }
  }, [productId]);

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm();

  // Quick and dirty way to handle values using react-hook-form
  useEffect(async () => {
    if (!productState.loading) {
      setValue("name", product.name);
      setValue("seasonnality", product.seasonnality);
      setValue("number_produced", product.number_produced);
    }
  }, [product]);

  // Normally used to add range from product, temporary commented
  // Handling ranges selector options
  // const [rangeOptions, setRangeOptions] = useState([]);
  // useEffect(() => {
  //   if (projectRanges.length > 0) {
  //     setRangeOptions(generateRangeOptions(projectRanges));
  //   }
  // }, [projectRanges]);

  // Dispatch updateProduct action when the product details are changed by the user only if the form changes
  useEffect(async () => {
    const productOriginalState = {
      name: product.name,
      id: product.id,
      seasonnality: product.seasonnality,
      number_produced: product.number_produced,
    };

    // Hack to avoid patching the product with the same values only because productLocalState changed because of a side effect
    if (
      JSON.stringify(productOriginalState) !==
        JSON.stringify(productLocalState) &&
      typeof product.id !== "undefined" &&
      Object.keys(productLocalState).length > 0 &&
      Object.keys(productOriginalState).length > 0 &&
      productState.loading === false
    ) {
      await dispatch(
        updateProduct(productLocalState, product.id, user, currentProject)
      );
      await dispatch(openSnackbar("Produit édité avec succès", "success"));
    }
  }, [productLocalState]);
  // Normally used to add range from product, temporary commented
  // FIXME : REFACTOR
  // const handleProductRangesChange = async (action) => {
  //   const actionDelete = async (rangeId) => {
  //     const rangeProductUUID = rangeProducts.filter(
  //       (rangeProduct) => rangeProduct.range.id === rangeId
  //     );
  //     if (rangeProductUUID.length > 0) {
  //       await dispatch(
  //         removeProductFromRange(rangeProductUUID[0].uuid, user.accessToken)
  //       );
  //     }
  //   };
  //   if (action.action === "remove-value") {
  //     await actionDelete(action.removedValue.value);
  //     await dispatch(
  //       openSnackbar("La gamme à  été dissociée du produit", "success")
  //     );
  //     await dispatch(fetchRangesOfAProduct(productId, user.accessToken));
  //   }

  //   if (action.action === "clear") {
  //     await action.removedValues.forEach((removedValue) =>
  //       actionDelete(removedValue.value)
  //     );
  //     await dispatch(
  //       openSnackbar(
  //         "Toutes les gammes ont été dissociée du produit",
  //         "success"
  //       )
  //     );
  //     await dispatch(fetchRangesOfAProduct(productId, user.accessToken));
  //   }

  //   if (action.action === "select-option") {
  //     await dispatch(
  //       addProductToRange(
  //         currentProject.id,
  //         action.option.value,
  //         productId,
  //         user.accessToken,
  //         {}
  //       )
  //     );
  //     await dispatch(
  //       openSnackbar("La gamme à  été associée au produit", "success")
  //     );
  //     await dispatch(fetchRangesOfAProduct(productId, user.accessToken));
  //   }
  // };
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label={
                    <Trans i18nKey="Nom du produit">Nom du produit *</Trans>
                  }
                  name="name"
                  inputRef={register({
                    required: "Required",
                  })}
                  defaultValue={product.name}
                  onChange={(e) => {
                    setProductForm({ ...productForm, name: e.target.value });
                  }}
                  error={"name" in errors}
                  helperText={
                    "name" in errors ? (
                      <Trans i18nKey="Veuillez entrer le nom du produit">
                        Veuillez entrer le nom du produit
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="seasonnality"
                  defaultValue={product.seasonnality}
                  onChange={(e) => {
                    setProductForm({
                      ...productForm,
                      seasonnality: e.target.value,
                    });
                  }}
                  label={<Trans i18nKey="Saisonnalité">Saisonnalité</Trans>}
                  name="seasonnality"
                  inputRef={register()}
                />
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="productRanges">Gammes : </label>
                {/* Normally used to add range from product, temporary commented
                <Select
                  inputId="productRanges"
                  // value={generateRangeOptions(rangesOfTheProduct)}
                  value={rangesOfTheProduct.map((range) => ({
                    value: range.id,
                    label: range.name,
                  }))}
                  isMulti
                  name="rangesOfTheProduct"
                  options={rangeOptions}
                  // className="basic-multi-select"
                  // classNamePrefix="select"
                  textFieldProps={{
                    label: "Label",
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  onChange={(value, action) =>
                    // eslint-disable-next-line prettier/prettier
                    handleProductRangesChange(action)}
                /> */}
                <Box className={classes.rangesBox} inputId="productRanges">
                  {rangesOfTheProduct.length === 0 ? (
                    <Typography style={{ color: "#555", userSelect: "none" }}>
                      Aucune gamme associée à ce produit
                    </Typography>
                  ) : (
                    rangesOfTheProduct.map((range) => (
                      <Chip key={range.id} label={range.name} size="small" />
                    ))
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="number_produced"
                  type="number"
                  defaultValue={product.number_produced}
                  inputRef={register()}
                  onBlur={(e) => onChangeNumberUnitProduced(e)}
                  onChange={(e) => {
                    setProductForm({
                      ...productForm,
                      number_produced: e.target.value,
                    });
                  }}
                  label={
                    <Trans i18nKey="Nombre d'unités produites">
                      Nombre d&apos;unités produites
                    </Trans>
                  }
                  name="number_produced"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Grid item xs={12}>
              <div>
                <input
                  type="file"
                  id="img"
                  name="product-image"
                  className={classes.inputImg}
                />
                <p>Image :</p>
                <InputLabel htmlFor="img">
                  <img
                    width="300px"
                    height="300px"
                    src={defaultImage}
                    alt="empty"
                  />
                </InputLabel>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProductDetail.propTypes = {
  productId: PropTypes.number.isRequired,
};
export default ProductDetail;
