/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(() => ({
  paddingBox: {
    padding: "12px",
  },
  margin: {
    marginTop: "16px",
    marginBottom: "16px",
  },
}));

function RangeDetails({ rangeDetails }) {
  console.log(rangeDetails);
  const classes = useStyles();
  if (rangeDetails.id === null) {
    return <></>;
  }
  return (
    <Paper>
      <Box className={classes.paddingBox}>
        <Grid container className={classes.marginBox}>
          <Grid
            item
            xs={12}
            className={classes.margin}
            style={{ fontWeight: "bold" }}
          >
            {rangeDetails.name}{" "}
            {rangeDetails.seasonnality
              ? "(" + rangeDetails.seasonnality + ")"
              : null}
          </Grid>
          <Grid item xs={12}>
            Nombre de clients:{" "}
            {rangeDetails.number_custom_by_year_number_check_total}
          </Grid>
          <Grid item xs={6} className={classes.margin}>
            CA TTC: {rangeDetails.sales_revenue_TTC} €
          </Grid>
          <Grid item xs={6} className={classes.margin}>
            CA HT: {rangeDetails.sales_revenue_HT} €
          </Grid>
          <Grid item xs={6} className={classes.margin}>
            Coefficient multiplicateur:{" "}
            {rangeDetails.range_multiplier_coefficient}
          </Grid>
          <Grid item xs={6} className={classes.margin}>
            Marge brute: {rangeDetails.gross_margin} €
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

RangeDetails.propTypes = {
  rangeDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    owner: PropTypes.number,
    seasonnality: PropTypes.string,
    tva_rate: PropTypes.number,
  }).isRequired,
};

export default RangeDetails;
