/* eslint-disable no-self-compare */
/* eslint-disable react/prop-types */
import { Grid } from "@material-ui/core";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProducts } from "../../redux/actions/productActions";
import {
  addProductToRange,
  removeProductFromRange,
  updateRangeProduct,
} from "../../redux/actions/rangeProductActions";
import { fetchRangeApi } from "../../services/api/rangeAPI";
import { copyProductToRangeProduct } from "../../services/range/RangeProductService";
import { computeEditableValues } from "../../services/range/RangeService";
import FieldWithSuffix from "../ElementsTable/FieldWithSuffix";
import FooterAddLine from "../ElementsTable/FooterAddLine";

const getTableTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #ddd",
        },
      },

      MuiFormControlLabel: {
        root: {
          "&:first-child": { display: "none" },
          "&:nth-child(2)": { display: "none" },
        },
      },

      MUIDataTableSelectCell: {
        fixedHeader: {
          position: "relative",
        },
        headerCell: {
          position: "relative",
        },
        fixedLeft: {
          position: "relative",
          padding: "0",
        },
      },

      MUIDataTableHeadCell: {
        fixedHeader: {
          position: "relative",
        },
      },
    },
  });

const useStyles = makeStyles(() => ({
  inputList: {
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
  },
  width100: {
    width: "100px",
  },
  tableFooter: {
    "& div": {
      visibility: "hidden",
    },
  },
}));

const RangeProducts = () => {
  const { t } = useTranslation();
  // const { real_mode } = useSelector((state) => state.ranges.range);
  // FIXME : J'ai besoin de la vrai valeur de real_mode pour conditionner l'affichage de certaines colonnes du tableau mais cela ralenti toute l'application.
  // FIXME : Voir https://github.com/ajinnov/web-popser/issues/30
  const { rangeId } = useParams();
  const { register, errors, control } = useForm({
    defaultValues: {
      rangeId,
    },
  });
  const popser = useSelector((state) => state.rangeProducts.popser);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [realMode, setRealMode] = useState(false);
  const onChangeField = (e) => console.log(e);
  const rangeProducts = useSelector(
    (state) => state.rangeProducts.rangeProducts
  );

  console.log("range products: ", rangeProducts);

  const sortedRangeProducts = rangeProducts.sort((a, b) => {
    return a.order - b.order;
  });

  const addProduct = async (product) => {
    const rangeProduct = copyProductToRangeProduct(product, {});
    if (product) {
      await dispatch(
        addProductToRange(
          currentProject.id,
          rangeId,
          product.id,
          user.accessToken,
          rangeProduct
        )
      );
    }
  };

  useEffect(async () => {
    fetchRangeApi(rangeId, user.accessToken).then((data) => {
      if (data) {
        setRealMode(data.real_mode);
      }
    });
    dispatch(fetchProducts(currentProject.id, user.accessToken));
  }, [rangeId]);

  const deleteRows = async (RowsDeleted) => {
    const ids = RowsDeleted.data.map((d) => d.dataIndex);
    const idsToDeleted = ids.map((index) => sortedRangeProducts[index]);

    await idsToDeleted.map(async (dataDelete) => {
      await dispatch(removeProductFromRange(dataDelete.uuid, user.accessToken));
    });
  };

  const options = {
    textLabels: {
      body: {
        noMatch: t(
          "Soit votre gamme ne contient pas de produits, soit vos critères de recherche n'ont rien donné. Ajoutez un produit ou revoyez votre recherche."
        ),
      },
    },
    sort: false,
    filter: false,
    filterType: "checkbox",
    enableNestedDataAccess: ".",
    onRowsDelete: deleteRows,
    customFooter: (
      count,
      page,
      rowsPerPage,
      onRowsPerPageChange,
      onPageChange
    ) => (
      <FooterAddLine
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        addProduct={addProduct}
        // eslint-disable-next-line no-shadow
        changePage={onPageChange}
        changeRowsPerPage={onRowsPerPageChange}
        rowsPerPageOptions={[10, 15, 100]}
        title={t("ajouter produit")}
        // hiddenPagination
      />
    ),
  };
  const [rowRangeProduct] = useState();

  const registerRangeProduct = (rangeProduct) => {
    // eslint-disable-next-line no-param-reassign
    sortedRangeProducts[sortedRangeProducts.indexOf(rangeProduct)].id =
      rowRangeProduct.id_replace;
  };

  const handleTtcPriceChange = async (event, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === event.rowData[0]
    );
    if (rangeProductsFiltered.length === 0) {
      return;
    }
    const currentRangeProduct = rangeProductsFiltered[0];
    const computedEditableValues = await computeEditableValues(
      {
        changed: "selling_price_vat_incl",
        newValue: value,
      },
      rangeProductsFiltered[0]
    );
    const rangeProduct = {
      uuid: currentRangeProduct.uuid,
      project: currentRangeProduct.project,
      range: currentRangeProduct.range,
      product: currentRangeProduct.product,
      ...computedEditableValues,
      selling_price_vat_incl: value.slice(0, -1),
    };
    dispatch(updateRangeProduct(rangeProduct, user.accessToken));
  };

  const handleRawMaterialCostChange = (event, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === event.rowData[0]
    );
    if (rangeProductsFiltered.length === 0) {
      return;
    }
    const currentRangeProduct = rangeProductsFiltered[0];
    const rangeProduct = {
      uuid: currentRangeProduct.uuid,
      project: currentRangeProduct.project,
      range: currentRangeProduct.range,
      product: currentRangeProduct.product,
      raw_material_cost_vat_excl: value.slice(0, -1),
    };
    dispatch(updateRangeProduct(rangeProduct, user.accessToken));
  };

  const handleVatRateChange = async (event, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === event.rowData[0]
    );
    if (rangeProductsFiltered.length === 0) {
      return;
    }
    const vat = value.slice(0, -1) !== "" ? value.slice(0, -1) : null;
    const computedEditableValues = await computeEditableValues(
      {
        changed: "vat",
        newValue: vat,
      },
      rangeProductsFiltered[0]
    );
    const currentRangeProduct = rangeProductsFiltered[0];
    const rangeProduct = {
      uuid: currentRangeProduct.uuid,
      project: currentRangeProduct.project,
      range: currentRangeProduct.range,
      product: currentRangeProduct.product,
      ...computedEditableValues,
      vat,
    };
    console.log("rangeProduct", rangeProduct);
    dispatch(updateRangeProduct(rangeProduct, user.accessToken));
  };

  const handleChangePollQuantity = async (event, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === event.rowData[0]
    );
    if (rangeProductsFiltered.length === 0) {
      return;
    }
    const currentRangeProduct = rangeProductsFiltered[0];
    const rangeProduct = {
      uuid: currentRangeProduct.uuid,
      project: currentRangeProduct.project,
      range: currentRangeProduct.range,
      product: currentRangeProduct.product,
      poll_quantity: value,
    };
    dispatch(updateRangeProduct(rangeProduct, user.accessToken));
  };

  const handleHTPriceChange = async (event, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === event.rowData[0]
    );
    if (rangeProductsFiltered.length === 0) {
      return;
    }
    const currentRangeProduct = rangeProductsFiltered[0];
    const computedEditableValues = await computeEditableValues(
      {
        changed: "selling_price_vat_excl",
        newValue: value.slice(0, -1),
      },
      rangeProductsFiltered[0]
    );
    const rangeProduct = {
      uuid: currentRangeProduct.uuid,
      project: currentRangeProduct.project,
      range: currentRangeProduct.range,
      product: currentRangeProduct.product,
      ...computedEditableValues,
      selling_price_vat_excl: value.slice(0, -1),
    };
    dispatch(updateRangeProduct(rangeProduct, user.accessToken));
  };

  useEffect(() => {
    if (rowRangeProduct) {
      if (`${rowRangeProduct.id}`.includes("new")) {
        registerRangeProduct(rowRangeProduct);
      } else {
        // updateRawMaterial();
      }
    }
  }, [rowRangeProduct, user]);
  const onChangeQuantitySold = (tableMeta, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === tableMeta.rowData[0]
    );
    /*
    if (rangeProductsFiltered.length === 0) {
      return;
    } */
    // FIXME : Lors du test avec Vuk, il y a un bug lors de la modification de la quantité vendue. La liste des produits de la gammes est non définie pour une raison inconnue ... Pour le moment j'ai mis un try catch un peu crade pour que ça passe.
    // The bug is not only on tests, to be more precise it's when updating a newly imported product.
    // To reproduce, add a new product to a range and update it's sold count value. The sortedRangeProducts (and rangeProducts) will have 1 less
    // element than the rangeProducts in the store EVEN THO the above console log also showing rangeProducts will have the correct number
    // of elements. It feels like the data is desynchronized between the component and this method.
    try {
      const currentRangeProduct = rangeProductsFiltered[0];
      const rangeProduct = {
        uuid: currentRangeProduct.uuid,
        project: currentRangeProduct.project,
        range: currentRangeProduct.range,
        product: currentRangeProduct.product,
        quantity_sold: value,
      };
      dispatch(updateRangeProduct(rangeProduct, user.accessToken));
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeDisplayNumber = (tableMeta, value) => {
    const rangeProductsFiltered = sortedRangeProducts.filter(
      (e) => e.id === tableMeta.rowData[0]
    );
    if (rangeProductsFiltered.length === 0) {
      return;
    }
    const currentRangeProduct = rangeProductsFiltered[0];
    const rangeProduct = {
      uuid: currentRangeProduct.uuid,
      project: currentRangeProduct.project,
      range: currentRangeProduct.range,
      product: currentRangeProduct.product,
      number_of_days_of_presentation: value,
    };
    dispatch(updateRangeProduct(rangeProduct, user.accessToken));
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "uuid",
      options: {
        display: false,
      },
    },
    {
      name: "product.name",
      label: t("Nom du produit"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <input
              type="text"
              id={`product.name${tableMeta.rowData[0]}`}
              className={classes.inputList}
              name={`product.name${tableMeta.rowData[0]}`}
              value={value}
              disabled
              style={{
                maxWidth: "150px",
                width: "auto",
                textOverflow: "ellipsis",
              }}
              title={value}
            />
          );
        },
      },
    },
    {
      name: "selling_price_vat_incl",
      label: t("Prix de vente TTC"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn={`selling_price_vat_incl${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={handleTtcPriceChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "quantity_sold",
      label: t("Quantités vendues"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn="quantity_sold"
              control={control}
              onChangeField={onChangeQuantitySold}
              tableMeta={tableMeta}
              errors={errors}
              suffix=""
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "number_of_days_of_presentation",
      label: t("Nombre de présentations"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn="number_of_days_of_presentation"
              control={control}
              onChangeField={onChangeDisplayNumber}
              tableMeta={tableMeta}
              errors={errors}
              suffix=""
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "raw_material_cost_vat_excl",
      label: t("Coût mat. unit."),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn={`raw_material_cost_vat_excl${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={handleRawMaterialCostChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "vat",
      label: t("Taux de TVA \n si différent"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn="vat"
              control={control}
              onChangeField={handleVatRateChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix="%"
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "selling_price_vat_excl",
      label: t("PV HT"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn={`selling_price_vat_excl${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={handleHTPriceChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "percent_of_sells",
      label: t("Pourcentage des Ventes"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              nameColumn={`percent_of_sells${tableMeta.rowData[0]}`}
              control={control}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("%")}
              register={register}
              updateValue={updateValue}
              value={value}
              disabled
            />
          );
        },
      },
    },
    {
      name: "display_index",
      label: t("Indice des ventes"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "popularity_index",
      label: t("Indice de popularité"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unit_gross_margin_vat_excluded",
      label: t("Marge Brute \n unitaire"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              nameColumn={`unit_gross_margin_vat_exluded${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={onChangeField}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
              disabled
            />
          );
        },
      },
    },
    {
      name: "total_unit_gross_margin_vat_exluded",
      label: t("Marge Brute \n totale"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              nameColumn={`total_unit_gross_margin_vat_exluded${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={onChangeField}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
              disabled
            />
          );
        },
      },
    },
    {
      name: "iles_popularity",
      label: t("Zone de Popularité Popser"),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "bcg_popularity",
      label: t("Zone de Popularité Boston"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "iles_profitability",
      label: t("Zone de rentabilité Popser"),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "bcg_profitability",
      label: t("Zone de rentabilité Boston"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "iles_rank",
      label: t("Classement Popser"),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "bcg_rank",
      label: t("Classement Boston"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];
  const [columnsState, setColumnsState] = useState(columns);

  useEffect(() => {
    if (realMode === false) {
      const cols = columns;
      cols[4] = {
        name: "quantity_sold",
        label: t("Quantités vendues prévisionnelles"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <FieldWithSuffix
                nameColumn="quantity_sold"
                control={control}
                onChangeField={onChangeQuantitySold}
                tableMeta={tableMeta}
                errors={errors}
                disabled
                suffix=""
                register={register}
                updateValue={updateValue}
                value={value}
              />
            );
          },
        },
      };
      cols.splice(4, 0, {
        name: "poll_quantity",
        label: t("Nombre de sondés"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <FieldWithSuffix
                isEdit
                nameColumn={`poll_quantity${tableMeta.rowData[0]}`}
                control={control}
                onChangeField={handleChangePollQuantity}
                tableMeta={tableMeta}
                errors={errors}
                suffix=""
                register={register}
                updateValue={updateValue}
                value={value}
                type="number"
              />
            );
          },
        },
      });
      setColumnsState(cols);
    } else {
      setColumnsState(columns);
    }
  }, [realMode]);

  useEffect(() => {
    if (popser) {
      // FIXME : This is a quick but dirty way of handling rank changes. If columns array shape change, this will be fucked up.
      const cols = columns;
      cols[cols.length - 2] = {
        name: "iles_rank",
        label: t("Classement Popser"),
        options: {
          filter: true,
          sort: true,
          display: popser.isPopser,
        },
      };
      cols[cols.length - 4] = {
        name: "iles_profitability",
        label: t("Rentabilité Popser"),
        options: {
          filter: true,
          sort: true,
          display: popser.isPopser,
        },
      };
      cols[cols.length - 6] = {
        name: "iles_popularity",
        label: t("Zone de Popularité Popser"),
        options: {
          filter: true,
          sort: true,
          display: popser.isPopser,
        },
      };
      cols[cols.length - 1] = {
        name: "bcg_rank",
        label: t("Classement Boston"),
        options: {
          filter: true,
          sort: true,
          display: !popser.isPopser,
        },
      };
      cols[cols.length - 3] = {
        name: "bcg_profitability",
        label: t("Rentabilité Boston"),
        options: {
          filter: true,
          sort: true,
          display: !popser.isPopser,
        },
      };
      cols[cols.length - 5] = {
        name: "bcg_popularity",
        label: t("Zone de Popularité Boston"),
        options: {
          filter: true,
          sort: true,
          display: !popser.isPopser,
        },
      };
      setColumnsState(cols);
    }
  }, [popser]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MuiThemeProvider theme={getTableTheme()}>
            <MUIDataTable
              data={sortedRangeProducts}
              columns={columnsState}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default RangeProducts;
