/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import { useSelector } from "react-redux";

import { spacing } from "@material-ui/system";
import {
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  Grid,
  makeStyles,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import ManagerFeedback from "../components/managerFeedback/managerFeedback";

const drawerWidth = 230;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiAppBar-colorPrimary {
    color: #FFF;
    background-color: #1976d2;
}
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flexshrink: 0;
    whitespace: "nowrap";
    position: absolute;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  commentsMode: {
    "& header": {
      backgroundColor: "#188038!important",
    },
  },
  width100: {
    width: "100%",
  },
  commentsScreenMode: {
    backgroundColor: "#18803826",
    width: "100%",
    minHeight: "100%",
    position: "fixed",
    zIndex: "4",
  },
  commentsModal: {
    backgroundColor: "white",
    border: "solid 1px black",
    width: "200px",
    height: "50px",
    position: "absolute",
    zIndex: "2",
    top: "244px",
    left: "222px",
  },
  managerFeedback: {
    zIndex: "5",
    position: "fixed",
    right: "1%",
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: "100%",
    },
  },
}));

const Dashboard = ({ children, routes, width }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const showComments = useSelector(
    (state) => state.managerFeedback.show_comments
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Sidebar
          routes={routes}
          PaperProps={{ style: { width: drawerWidth } }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
      </Drawer>
      <AppContent
        className={[
          classes.width100,
          showComments.size_comments_screen !== 0 ? classes.commentsMode : null,
        ]}
      >
        <Header onDrawerToggle={handleDrawerToggle} />
        {showComments.size_comments_screen !== 0 ? (
          <>
            <div className={classes.commentsScreenMode} />
          </>
        ) : null}
        <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
          <Grid container spacing={4}>
            <Grid item xs={showComments.size_other_screen}>
              {children}
            </Grid>
            {showComments.size_comments_screen !== 0 ? (
              <Grid
                item
                className={classes.managerFeedback}
                xs={showComments.size_comments_screen}
              >
                <ManagerFeedback />
              </Grid>
            ) : null}
          </Grid>
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  );
};

export default withWidth()(Dashboard);
