import styled from "styled-components";

import React from "react";

import {
  ListItem as MuiListItem,
  Grid,
  Hidden,
  List,
  ListItemText,
} from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
  z-index: 1;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block !important;
  width: auto !important;
  padding-left: ${(props) => props.theme.spacing(2)}px !important;
  padding-right: ${(props) => props.theme.spacing(2)}px !important;

  &,
  &:hover,
  &:active {
    color: #000 !important;
  }
`;

function Footer() {
  return (
    <Wrapper style={{ zIndex: 0 }}>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem component="a" href="#">
                <ListItemText primary="Besoin d'aide" />
              </ListItem>
              <ListItem component="a" href="#">
                <ListItemText primary="Conditions de service" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Popser`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
